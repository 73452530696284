import React, { useEffect } from "react";

const SMS = () => {
  useEffect(() => {
    window.location.href = "https://api.bodhiness.com"; // Redirect to Google
  }, []); // Empty dependency array to ensure it runs only once on mount

  return (
    <div className="h-screen flex flex-col hide-scroll-bar scrollbar-hide">
    </div>
  );
};

export default SMS;
